var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',{staticClass:"text-h1"},[_vm._v("User Profile")])],1),_c('v-snackbar',{attrs:{"color":"#58abe7","centered":"","timeout":3000,"elevation":"24"},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarContent)+" ")]),_c('v-row',{staticClass:"mt-6"},[_c('v-col',{attrs:{"cols":"12"}},[(_vm.currentUser)?_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.currentUser.name)+" ")]),_c('v-card-text',{staticClass:"body-1 white--text font-weight-light mt-n7"},[_c('br'),_c('br'),_vm._v(" "+_vm._s(_vm.currentUser.email)+" "),_c('v-row',{staticClass:"mt-4 mb-4"},[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Type")]),_c('th',{staticClass:"text-left"},[_vm._v("Key")])])]),_c('tbody',_vm._l((_vm.keys),function(item){return _c('tr',{key:item.id},[(item.name == 'addon')?_c('td',[_vm._v("GSheet Addon")]):_vm._e(),(item.name == 'plugin')?_c('td',[_vm._v("Scraper Plugin")]):_vm._e(),_c('td',{staticStyle:{"min-width":"380px"}},[_vm._v(" "+_vm._s(item.value)+" ")]),_c('td',[_vm._v(" ")]),(item.name == 'addon')?_c('td',[_c('v-tooltip',{attrs:{"color":"#121D30","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"font-weight-light body-1 text-capitalize",attrs:{"color":"primary"},on:{"click":function($event){return _vm.generateKey(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("mdi-reload")])],1)]}}],null,true),model:{value:(_vm.showRegenerateTooltip1),callback:function ($$v) {_vm.showRegenerateTooltip1=$$v},expression:"showRegenerateTooltip1"}},[_c('span',[_vm._v("Regenerate Addon Key")])]),(!item.value || item.value == '---')?_c('v-tooltip',{attrs:{"color":"#121D30","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(!item.value || item.value == '---')?_c('v-btn',_vm._g(_vm._b({staticClass:"font-weight-light body-1 text-capitalize ml-3 accent",attrs:{"disabled":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("mdi-content-copy")])],1):_vm._e()]}}],null,true),model:{value:(_vm.showCopyTooltip1),callback:function ($$v) {_vm.showCopyTooltip1=$$v},expression:"showCopyTooltip1"}},[_c('span',[_vm._v("Copy Addon Key")])]):_c('v-tooltip',{attrs:{"color":"#121D30","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"font-weight-light body-1 text-capitalize ml-3 accent",on:{"click":function($event){return _vm.copyText(item.value)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("mdi-content-copy")])],1)]}}],null,true),model:{value:(_vm.showCopyTooltip1),callback:function ($$v) {_vm.showCopyTooltip1=$$v},expression:"showCopyTooltip1"}},[_c('span',[_vm._v("Copy Addon Key")])])],1):_vm._e(),(item.name == 'plugin')?_c('td',[_c('v-tooltip',{attrs:{"color":"#121D30","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"font-weight-light body-1 text-capitalize",attrs:{"color":"primary"},on:{"click":function($event){return _vm.generateKey(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("mdi-reload")])],1)]}}],null,true),model:{value:(_vm.showRegenerateTooltip2),callback:function ($$v) {_vm.showRegenerateTooltip2=$$v},expression:"showRegenerateTooltip2"}},[_c('span',[_vm._v("Regenerate Plugin Key")])]),(!item.value || item.value == '---')?_c('v-tooltip',{attrs:{"color":"#121D30","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(!item.value || item.value == '---')?_c('v-btn',_vm._g(_vm._b({staticClass:"font-weight-light body-1 text-capitalize ml-3 accent",attrs:{"disabled":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("mdi-content-copy")])],1):_vm._e()]}}],null,true),model:{value:(_vm.showCopyTooltip2),callback:function ($$v) {_vm.showCopyTooltip2=$$v},expression:"showCopyTooltip2"}},[_c('span',[_vm._v("Copy Plugin Key")])]):_c('v-tooltip',{attrs:{"color":"#121D30","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"font-weight-light body-1 text-capitalize ml-3 accent",on:{"click":function($event){return _vm.copyText(item.value)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("mdi-content-copy")])],1)]}}],null,true),model:{value:(_vm.showCopyTooltip2),callback:function ($$v) {_vm.showCopyTooltip2=$$v},expression:"showCopyTooltip2"}},[_c('span',[_vm._v("Copy Plugin Key")])])],1):_vm._e(),(item.name == 'addon')?_c('td',[_c('v-tooltip',{attrs:{"color":"#121D30","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"target":"_blank","href":_vm.downloadAddon,"color":"primary"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"#112748"}},[_vm._v("mdi-folder-google-drive")])],1)]}}],null,true),model:{value:(_vm.showDownloadTooltip3),callback:function ($$v) {_vm.showDownloadTooltip3=$$v},expression:"showDownloadTooltip3"}},[_c('span',[_vm._v("Open Google Sheets (Addon)")])])],1):_vm._e(),(item.name == 'plugin')?_c('td',[_c('v-row',{staticClass:"no-gutters mt-6"},[_c('v-col',[_c('v-tooltip',{attrs:{"color":"#121D30","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"target":"_blank","href":_vm.downloadPluginChrome,"color":"primary"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"#112748"}},[_vm._v("mdi-google-chrome")])],1),_vm._v("       ")]}}],null,true),model:{value:(_vm.showDownloadTooltip2),callback:function ($$v) {_vm.showDownloadTooltip2=$$v},expression:"showDownloadTooltip2"}},[_c('span',[_vm._v("Download Plugin (Chrome)")])])],1),_c('v-col',[_c('v-tooltip',{attrs:{"color":"#121D30","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"href":_vm.downloadPluginFirefox,"color":"accent","target":"_blank"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"#112748"}},[_vm._v("mdi-firefox")])],1)]}}],null,true),model:{value:(_vm.showDownloadTooltip1),callback:function ($$v) {_vm.showDownloadTooltip1=$$v},expression:"showDownloadTooltip1"}},[_c('span',[_vm._v("Download Plugin (Firefox)")])])],1)],1)],1):_vm._e()])}),0)]},proxy:true}],null,false,932154775)})],1)],1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }