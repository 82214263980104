<template>
  <div>
    <v-toolbar flat>
      <v-toolbar-title class="text-h1">User Profile</v-toolbar-title>
    </v-toolbar>
    <v-snackbar
      v-model="snackbar"
      color="#58abe7"
      centered
      :timeout="3000"
      elevation="24"
    >
      {{ snackbarContent }}
    </v-snackbar>
    <v-row class="mt-6">
      <v-col cols="12">
        <v-card flat v-if="currentUser">
          <v-card-title>
            {{ currentUser.name }}
          </v-card-title>
          <v-card-text class="body-1 white--text font-weight-light mt-n7">
            <br /><br />
            <!--      Last login: {{ formatDate(currentUser.last_login_at) }}<br /><br />
            Created: {{ formatDate(currentUser.created_at) }}<br /><br /> -->
            {{ currentUser.email }}
            <v-row class="mt-4 mb-4">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Type</th>
                      <th class="text-left">Key</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in keys" :key="item.id">
                      <td v-if="item.name == 'addon'">GSheet Addon</td>
                      <td v-if="item.name == 'plugin'">Scraper Plugin</td>
                      <td style="min-width: 380px">
                        {{ item.value }}
                      </td>
                      <td>&nbsp;</td>
                      <td v-if="item.name == 'addon'">
                        <v-tooltip
                          color="#121D30"
                          v-model="showRegenerateTooltip1"
                          top
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              @click="generateKey(item.id)"
                              class="font-weight-light body-1 text-capitalize"
                              color="primary"
                              ><v-icon color="secondary">mdi-reload</v-icon>
                            </v-btn>
                          </template>
                          <span>Regenerate Addon Key</span>
                        </v-tooltip>

                        <v-tooltip
                          v-if="!item.value || item.value == '---'"
                          color="#121D30"
                          v-model="showCopyTooltip1"
                          top
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              v-if="!item.value || item.value == '---'"
                              disabled
                              class="font-weight-light body-1 text-capitalize ml-3 accent"
                              ><v-icon color="secondary"
                                >mdi-content-copy</v-icon
                              >
                            </v-btn>
                          </template>
                          <span>Copy Addon Key</span>
                        </v-tooltip>

                        <v-tooltip
                          v-else
                          color="#121D30"
                          v-model="showCopyTooltip1"
                          top
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              @click="copyText(item.value)"
                              class="font-weight-light body-1 text-capitalize ml-3 accent"
                              ><v-icon color="secondary"
                                >mdi-content-copy</v-icon
                              >
                            </v-btn>
                          </template>
                          <span>Copy Addon Key</span>
                        </v-tooltip>
                      </td>

                      <td v-if="item.name == 'plugin'">
                        <v-tooltip
                          color="#121D30"
                          v-model="showRegenerateTooltip2"
                          bottom
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              @click="generateKey(item.id)"
                              class="font-weight-light body-1 text-capitalize"
                              color="primary"
                              ><v-icon color="secondary">mdi-reload</v-icon>
                            </v-btn>
                          </template>
                          <span>Regenerate Plugin Key</span>
                        </v-tooltip>

                        <v-tooltip
                          v-if="!item.value || item.value == '---'"
                          color="#121D30"
                          v-model="showCopyTooltip2"
                          bottom
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              v-if="!item.value || item.value == '---'"
                              disabled
                              class="font-weight-light body-1 text-capitalize ml-3 accent"
                              ><v-icon color="secondary"
                                >mdi-content-copy</v-icon
                              >
                            </v-btn>
                          </template>
                          <span>Copy Plugin Key</span>
                        </v-tooltip>

                        <v-tooltip
                          v-else
                          color="#121D30"
                          v-model="showCopyTooltip2"
                          bottom
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              @click="copyText(item.value)"
                              class="font-weight-light body-1 text-capitalize ml-3 accent"
                              ><v-icon color="secondary"
                                >mdi-content-copy</v-icon
                              >
                            </v-btn>
                          </template>
                          <span>Copy Plugin Key</span>
                        </v-tooltip>
                      </td>

                      <td v-if="item.name == 'addon'">
                        <v-tooltip
                          color="#121D30"
                          v-model="showDownloadTooltip3"
                          top
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              target="_blank"
                              :href="downloadAddon"
                              color="primary"
                              ><v-icon color="#112748"
                                >mdi-folder-google-drive</v-icon
                              ></v-btn
                            >
                          </template>
                          <span>Open Google Sheets (Addon)</span>
                        </v-tooltip>
                      </td>
                      <td v-if="item.name == 'plugin'">
                        <v-row class="no-gutters mt-6">
                          <v-col>
                            <v-tooltip
                              color="#121D30"
                              v-model="showDownloadTooltip2"
                              bottom
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  v-bind="attrs"
                                  v-on="on"
                                  target="_blank"
                                  :href="downloadPluginChrome"
                                  color="primary"
                                  ><v-icon color="#112748"
                                    >mdi-google-chrome</v-icon
                                  ></v-btn
                                >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              </template>
                              <span>Download Plugin (Chrome)</span>
                            </v-tooltip>
                          </v-col>

                          <v-col>
                            <v-tooltip
                              color="#121D30"
                              v-model="showDownloadTooltip1"
                              bottom
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  v-bind="attrs"
                                  v-on="on"
                                  :href="downloadPluginFirefox"
                                  color="accent"
                                  target="_blank"
                                  ><v-icon color="#112748"
                                    >mdi-firefox</v-icon
                                  ></v-btn
                                >
                              </template>
                              <span>Download Plugin (Firefox)</span>
                            </v-tooltip>
                          </v-col>
                        </v-row>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { formatDate } from "@/utils/common";
import {
  getUser,
  userApikeyTypes,
  getUserKeys,
  userApikeyPost,
} from "@/clients/tracelight";
import { customersCustomerIdEmailDomainsDomainIdDelete } from "../clients/tracelight";

export default {
  name: "UserProfile",
  data: () => ({
    formatDate: formatDate,
    snackbarContent: null,
    snackbar: false,
    currentUser: null,
    loadingUserKey: true,
    showRegenerateTooltip1: false,
    showCopyTooltip1: false,
    showRegenerateTooltip2: false,
    showCopyTooltip2: false,
    showDownloadTooltip1: false,
    showDownloadTooltip2: false,
    showDownloadTooltip3: false,
    downloadAddon: "https://docs.google.com/spreadsheets/u/0/?tgif=d",
    downloadPluginFirefox:
      "https://drive.google.com/drive/u/0/folders/1zr9RSWhvhvArytu-a4oBxSh02qKi3NmR",
    downloadPluginChrome:
      "https://chrome.google.com/webstore/detail/tracelight/llkcigggadohdfjbeeengigfhijppiom",
    keys: [],
  }),
  mounted() {
    getUser({}, {})
      .then((result) => {
        this.currentUser = result.data.data;

        userApikeyTypes({}, {})
          .then((result) => {
            this.keys = result.data.data;
            this.getKeys(this.currentUser.id);
            console.log(this.currentUser.id);
          })
          .catch(function (error) {
            console.error(error);
            alert(error);
          });
      })
      .catch(function (error) {
        console.error(error);
        alert(error);
      });
  },

  methods: {
    getKeys(userId) {
      let params = { user_id: userId };
      let payload = {};
      getUserKeys(params, payload)
        .then((result) => {
          console.log(result);
          this.replaceKeys(result.data.data);
        })
        .catch(function (error) {
          console.error(error);
          alert(error);
        });
    },
    replaceKeys(newKeys) {
      console.log(this.keys);

      this.keys = this.keys.map((k) => {
        k.value = "---";
        return k;
      });
      for (let key of newKeys) {
        for (let keyType of this.keys) {
          if (key.name == keyType.name) {
            keyType.value = key.value;
          }
        }
      }
    },
    generateKey(id) {
      let params = {};
      let payload = { type: id };
      userApikeyPost(payload, params)
        .then((result) => {
          console.log(result);
          this.getKeys(this.currentUser.id);
        })
        .catch(function (error) {
          console.error(error);
          alert(error);
        });
    },
    copyText(text) {
      const el = document.createElement("textarea");
      el.value = text;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      this.snackbarContent = "Copied!";
      this.snackbar = true;
    },
  },
};
</script>
<style scoped>
.theme--dark.v-data-table {
  background-color: transparent !important;
}
.theme--dark.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: transparent !important;
}
</style>
